// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable simple-import-sort/imports */
import React from "react"

import { Layout } from "../components/layout"
import { NotFound } from "../components/not-found"

export default function AboutPage() {
  return (
    <Layout pageTitle="Not found" color="blue">
      <NotFound />
    </Layout>
  )
}
