import React from "react"

import s from "./not-found.module.scss"

export const NotFound = () => (
  <div id={s.notFound}>
    <span id={s.before}> </span>
    <span>Not&nbsp; Found</span>
  </div>
)
